import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Seed Comments`}</h1>
    <p>{`A seed comment is a comment submitted by the `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` `}<a parentName="p" {...{
        "href": "/owner",
        "title": "owner"
      }}>{`owner`}</a>{` in the Polis `}<a parentName="p" {...{
        "href": "/admin-interface",
        "title": "admin-interface"
      }}>{`admin-interface`}</a>{`. They're an important first step toward mapping out the landscape of opinions. Plus, they set the tone of the conversation and teach the initial participants how to write good `}<a parentName="p" {...{
        "href": "/comments",
        "title": "comments"
      }}>{`comments`}</a>{`. Without seed comments, initial participants don't have anything to vote on, and might leave a conversation without engaging.`}</p>
    <p>{`Seed comments are different from comments submitted by participants in that they:`}</p>
    <ol>
      <li parentName="ol">{`Are not associated with an account.`}</li>
      <li parentName="ol">{`Don't have an initial vote associated with them.`}</li>
    </ol>
    <p>{`If you'd like to add seed comments as 'yourself', submit them via the participation interface (ensure you're logged in). See: `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/polis/issues/745"
      }}>{`https://github.com/compdemocracy/polis/issues/745`}</a>{`.`}</p>
    <h3>{`Best practices for seed comments`}</h3>
    <ul>
      <li parentName="ul">{`Add at least 10, but 25-40 is better.`}</li>
      <li parentName="ul">{`Use simple, clear language.`}</li>
      <li parentName="ul">{`Restrict each comment to a single point.`}</li>
      <li parentName="ul">{`Make sure there's a diversity of opinions represented — as many different responses to the prompt as possible.`}</li>
      <li parentName="ul">{`Include both controversial and potentially consensus statements.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      